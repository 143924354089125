import React from "react"
import styled from 'styled-components'
// import intl from 'react-intl-universal'
import withI18next from '../components/withI18next';

import Layout from "../components/layout"
import SEO from "../components/seo"
import contract from '../contract.pdf'
import contract_en from '../contract_en.pdf';
import download from '../images/download.png';

const contracts = {
  en: contract_en,
  zh: contract,
};

const IHeader = styled.header.attrs({ className: 'text-white text-center', id: 'product-header' })`
  height: 280px;
  margin-top: -80px;
  background: linear-gradient(to bottom, #708BFF, #8BA1FF);
  h2 {
    position: relative;
    top: 200px;
  }
`;

const IContent = styled.div`
  padding: 0 4em;
  padding-top: 47px;
  h3 {
    font-size: 34px;
    color: #708BFF;
  }
  .download-block {
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .download {
    color: #3B416D;
    font-size: 14px;
    padding-left: 20px;
    line-height: 20px;
    background: left / 16px 16px no-repeat url(${download});
    border-bottom: 1px solid #708BFF;
  }
  .contract-content {
    max-width: 1170px;
    margin: 0 auto;
    padding-bottom: 150px;
    .contract-paragraph {
      font-size: 16px;
      h4 {
        color: #708BFF;
        font-size: 24px;
      }
      p {
        line-height: 28px;
        &.en br {
          margin-bottom: 5px;
        }
      }
      br {
        margin-bottom: 30px;
      }
    }
  }
`;

const Contract = ({ pageContext: { locale }, t}) => {

  return (
    <Layout type="contract">
      <SEO title="合同" description="猫头鹰数据" keywords={[`专业的电竞数据`, `猫头鹰数据`, `电竞数据`, "owlScore"]} />
      <IHeader locale={locale}>
        <h2>{t('contract.header')}</h2>
      </IHeader>
      <IContent>
        <h3 className="mb-3 text-center">{t('contract.title')}</h3>
        <div className="download-block text-center">
          <a className="download" href={contracts[locale]} download="contract.pdf">{t('contract.download')}</a>
        </div>
        <div className="contract-content">
          {t('contract.content', { returnObjects: true }).map(c => (
            <div key={c.title} className="contract-paragraph">
              <h4 className="mt-5 mb-4">{c.title}</h4>
              <p className={locale} dangerouslySetInnerHTML={{ __html: c.content }} />
            </div>))
          }
        </div>
      </IContent>
    </Layout>
  );
}

export default withI18next({ ns: 'common' })(Contract)
